.modal_styles {
  border-radius: 3px;
  width: 75%;
}

.modal_upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 2px;
}

.modal_upper > h1 {
  font-family: var(--font-form);
  text-align: left;
}

.modal_styles > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 20px 30px;
  font-size: 20px;
  font-weight: 500;
}

.modal_closeIcon {
  color: #D93A32;
  transform: scale(1.5);
}

.modal_closeIcon:hover {
  cursor: pointer;
  color: #FF6961;
}

.modal_rsvp1 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 20px 20px 2px;
  font-size: 22px;
  font-weight: 500;
}

.modal_rsvp2 {
  display: flex;
  align-items: center;
}

.modal_rsvp2 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px 20px;
  font-size: 22px;
}

.modal_rsvp2 > h3 {
  font-family: var(--font-form);
  font-size: 22px;
  margin-bottom: 8px;
  font-weight: 500;
}

.modal_styles > p {
  font-family: var(--font-form);
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  margin: 0 20px 10px;
}

.modal_rsvp3 {
  display: flex;
  margin-left: 18px;
  align-items: baseline;
}

.modal_rsvp3 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px;
  font-size: 22px;
}

.modal_rsvp3 > p {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px;
  font-size: 20px;
  font-weight: 500;
}

.LoggedIn {
  background-color: var(--light-circlek-color);
  color: white;
  border: none;
  border-radius: 17px;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-form);
  margin: 0 10px;
}

.LoggedIn:hover {
  background-color: var(--circlek-color);
  cursor: pointer;
}


.notLoggedIn {
  background-color: light-grey;
  border: none;
  border-radius: 17px;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 800;
  pointer-events: none;
  font-family: var(--font-form);
  margin: 0 10px;
}

@media only screen and (min-width: 1024px) {
  .modal_styles {
    border-radius: 3px;
    width: 1100px;
  }
  
  .modal_upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px 2px;
  }
  
  .modal_upper > h1 {
    font-family: var(--font-form);
    text-align: left;
  }
  
  .modal_styles > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 20px 30px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .modal_closeIcon {
    color: #D93A32;
    transform: scale(1.5);
  }
  
  .modal_closeIcon:hover {
    cursor: pointer;
    color: #FF6961;
  }
  
  .modal_rsvp1 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 20px 20px 2px;
    font-size: 22px;
    font-weight: 500;
  }
  
  .modal_rsvp2 {
    display: flex;
    align-items: center;
  }
  
  .modal_rsvp2 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px 20px;
    font-size: 22px;
  }
  
  .modal_rsvp2 > h3 {
    font-family: var(--font-form);
    font-size: 22px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .modal_styles > p {
    font-family: var(--font-form);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    margin: 0 20px 10px;
  }
  
  .modal_rsvp3 {
    display: flex;
    margin-left: 18px;
    align-items: baseline;
  }
  
  .modal_rsvp3 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px;
    font-size: 22px;
  }
  
  .modal_rsvp3 > p {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .LoggedIn {
    background-color: var(--light-circlek-color);
    color: white;
    border: none;
    border-radius: 17px;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-form);
  }
  
  .LoggedIn:hover {
    background-color: var(--circlek-color);
    cursor: pointer;
  }
  
  
  .notLoggedIn {
    background-color: light-grey;
    border: none;
    border-radius: 17px;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 800;
    pointer-events: none;
    font-family: var(--font-form);
  }
}