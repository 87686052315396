.entry {
  margin-top: -10px;
  height: 100px;
}

.entry_list {
  height: 80px;
  display: relative;
  overflow-y: auto;
}

.entry_number {
  font-family: var(--font-form);
  font-size: 20px;
  text-align: right;
  padding-right: 5px;
  position: static;
}

/*@media only screen and (min-width: 768px) {
  .entry > p {
    font-family: var(--font-style);
    font-size: 16px;
    text-align: left;
    margin-left: 5px;
    margin-top: -55px;
    position: static;
  }
}*/
