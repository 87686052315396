.calendar {
  margin: 110px auto 0;
  width: 95%;
}

.calendar_categories {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 125px auto 100px;
}

.calendar_categories > h1 {
  border-radius: 50px;
  padding: 30px 20px;
  min-width: 140px;
  font-size: 27px;
  text-align: center;
  font-family: var(--font-form);
}

.calendar_categoriesService {
  background-color: var(--service-color);
}

.calendar_categoriesSocial {
  background-color: var(--social-color);
}

.calendar_categoriesCommittee {
  background-color: var(--committee-color);
}

.calendar_categoriesKiwanis {
  background-color: var(--kiwanis-color);
}

.calendar_categoriesFundraising {
  background-color: var(--fundraising-color);
}

.calendar_categoriesDivision {
  background-color: var(--division-color);
}

.calendar_categoriesLeadership {
  background-color: var(--leadership-color);
}

.calendar_profile {
  margin: 50px 0;
}

@media only screen and (min-width: 1024px) {
  .calendar {
    margin: 175px auto 0;
    max-width: 1920px;
    width: 95%;
  }
  
  .calendar_categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1350px;
    margin: 125px auto 100px;
  }
  
  .calendar_categories > h1 {
    border-radius: 50px;
    padding: 30px 20px;
    min-width: 140px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
  }
  
  .calendar_categoriesService {
    background-color: var(--service-color);
  }
  
  .calendar_categoriesSocial {
    background-color: var(--social-color);
  }
  
  .calendar_categoriesCommittee {
    background-color: var(--committee-color);
  }
  
  .calendar_categoriesKiwanis {
    background-color: var(--kiwanis-color);
  }
  
  .calendar_categoriesFundraising {
    background-color: var(--fundraising-color);
  }
  
  .calendar_categroiesDivision {
    background-color: var(--division-color);
  }

  .calendar_categoriesLeadership {
    background-color: var(--leadership-color);
  }
  
  .calendar_profile {
    margin: 50px 0;
  }
}
