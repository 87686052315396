.loginC_total {
  display: flex;
}

.loginC_signout {
  flex: 0.5;
  margin: 0 10px 0 120px;
  padding: 25px;
  background-color: var(--lightest-circlek-color);
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.loginC button {
  border-radius: 10px;
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 500;
  border: none;
}

.loginC_signout > h2 {
  font-family: var(--font-form);
}

.loginC_signout > img {
  width: 150px;
  display: block;
  margin: 10px auto;
  border-radius: 50%;
}

.loginC_signout > button {
  background-color: var(--lighter-circlek-color);
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
}

.loginC_signout > button:hover {
  background-color: var(--light-circlek-color);
  cursor: pointer;
  color: white;
}

.loginC_entry {
  flex: 0.5;
  margin: 0 120px 0 10px;
  padding: 25px;
}

.loginC_entry > h1 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 36px;
  color: var(--circlek-color);
}
