.family {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.family > h3 {
  text-align: center;
  position: relative;
  top: 210px;
  font-family: var(--font-form);
}
