h1 {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
} 
h2 {
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.navbar {
  background-color: var(--circlek-color);
  justify-content: center;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  background-image: url("./images/Cloud.png");
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat; 
}

.navbar_inner {
  position: relative;
  top: 10px;
  margin: 0 auto;
  max-width: 1500px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height:50%;
}

.navbar_title {
  display: flex;
  align-items: baseline;
  position: relative;
}

.navbar_title > h1 {
  font-size: 50px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
  white-space: nowrap;
}

.navbar_title > h2 {
  margin: 0 0 0 10px;
  font-size: 22px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
  white-space: nowrap;
}

.navbar_lower {
  display: flex;
  align-items: center;
  position: relative;
  right: 5px;
  bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  h1 {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  } 
  h2 {
    display: block;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }
  .navbar {
    background-color: var(--circlek-color);
    justify-content: center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    background-image: url("./images/Cloud.png");
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat; 
    padding-top: 6px;
  }
  
  .navbar_inner {
    position: relative;
    top: 10px;
    margin: 0 auto;
    max-width: 1500px;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
  
  .navbar_title {
    display: flex;
    align-items: baseline;
    position: relative;
  }
  
  .navbar_title > h1 {
    font-size: 70px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
    white-space: nowrap;
  }
  
  .navbar_title > h2 {
    margin: 0 0 0 10px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
    white-space: nowrap;
  }
  
  .navbar_title:hover {
    border-bottom: 2px solid var(--background-color);
    position: relative; 
    bottom: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  
  .navbar_lower {
    display: flex;
    align-items: center;
    position: relative;
    right: 0px;
    bottom: 0px;
  }
}
