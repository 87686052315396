.calendarCogs_months {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 20px;
}

.calendarCogs_months > h1{
  color: var(--circlek-color);
  font-family: var(--font-form);
  font-size: 25px;
  font-weight: 800;
}

.calendarCogs_monthIcon_left {
  position: relative;
  right: 10px;
  background-color: var(--light-circlek-color);
  border-radius: 50% !important;
  color: white;
  transform: scale(1.5);
  margin-top: 4px;
}

.calendarCogs_monthIcon_right {
  background-color: var(--light-circlek-color);
  border-radius: 50% !important;
  color: white;
  transform: scale(1.5);
  margin-left: 20px;
  position: relative;
  right: 10px;
  font-weight: 1000;
}

.calendarCogs_monthIcon_left:hover {
  cursor: pointer;
  background-color: var(--circlek-color);
}

.calendarCogs_monthIcon_right:hover {
  cursor: pointer;
  background-color: var(--circlek-color);
}

.calendarCogs_calendar {
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 10px 0;
}

.calendarCogs_days {
  overflow: hidden;
  color: white;
  background-color: var(--light-circlek-color);
  border-radius: 15px 15px 0 0;
  line-height: 40px;
  font-family: var(--font-form);
  font-size: 16px;
  font-weight: 500;
}

.calendarCogs_week {
  height: 120px;
}

.calendarCogs_week > th {
  min-width: 14%;
  background-color: white;
  border-bottom: 2px solid black;
}


@media only screen and (min-width: 1024px) {
  .calendarCogs_months {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 1350px;
    margin: 0 auto 20px;
  }
  
  .calendarCogs_months > h1{
    color: var(--circlek-color);
    font-family: var(--font-form);
    font-size: 70px;
    font-weight: 800;
  }
  
  .calendarCogs_monthIcon_left {
    position: relative;
    right: 10px;
    background-color: var(--light-circlek-color);
    border-radius: 50% !important;
    color: white;
    transform: scale(1.5);
    margin-top: 4px;
  }
  
  .calendarCogs_monthIcon_right {
    background-color: var(--light-circlek-color);
    border-radius: 50% !important;
    color: white;
    transform: scale(1.5);
    margin-left: 20px;
    position: relative;
    right: 10px;
    font-weight: 1000;
  }
  
  .calendarCogs_monthIcon_left:hover {
    cursor: pointer;
    background-color: var(--circlek-color);
  }
  
  .calendarCogs_monthIcon_right:hover {
    cursor: pointer;
    background-color: var(--circlek-color);
  }
  
  .calendarCogs_calendar {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 10px 0;
  }
  
  .calendarCogs_days {
    overflow: hidden;
    color: white;
    background-color: var(--light-circlek-color);
    border-radius: 15px 15px 0 0;
    line-height: 40px;
    font-family: var(--font-form);
    font-size: 20px;
    font-weight: 500;
  }
  
  .calendarCogs_week {
    height: 120px;
  }
  
  .calendarCogs_week > th {
    min-width: 180px;
    background-color: white;
    border-bottom: 2px solid black;
  }
}

