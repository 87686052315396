@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
* {
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 768px) {
  .fbWidget {
    margin-left: 40px;
    width: 500px;
  }
}

.family {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.family > h3 {
  text-align: center;
  position: relative;
  top: 210px;
  font-family: var(--font-form);
}

.tenent {
  display: flex;
  flex-direction: column;
}

.tenent > h3 {
  text-align: center;
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0 0;
}

.tenent > p {
  z-index: 1;
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 18px 0;
  position: relative;
  top: 42px;
  text-align: center;
}

.Service[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Service[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Service[value]::-webkit-progress-value {
  background-color: #F0E189;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.Fellowship[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Fellowship[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Fellowship[value]::-webkit-progress-value {
  background-color: #199BC7;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.Leadership[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Leadership[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Leadership[value]::-webkit-progress-value {
  background-color: #F47D2C;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.tenent_logo {
  flex: 0.20 1;
  max-width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 150px;
}

@media only screen and (min-width: 1024px) {
  .tenent {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 1300px;
  }

  .tenent > h3 {
    flex: 0.15 1;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }

  .tenent > p {
    flex: 0.15 1;
    z-index: 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 18px 0;
    position: relative;
    top: 0;
    left: 500px;
  }

  progress {
    flex: 0.5 1;
    position: relative;
    top: 0px;
    right: 120px
  }

  .Service[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Service[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Service[value]::-webkit-progress-value {
    background-color: #F0E189;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .Fellowship[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Fellowship[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Fellowship[value]::-webkit-progress-value {
    background-color: #199BC7;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .Leadership[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Leadership[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Leadership[value]::-webkit-progress-value {
    background-color: #F47D2C;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .tenent_logo {
    flex: 0.20 1;
    max-width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 150px;
  }
}

.footer {
  margin-top: 15px;
  text-align: center;
  background-image: url(/static/media/Black+Masthead.9603df99.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; 
  height: 200px;
}

.footer_socialMedia > a > .MuiSvgIcon-root {
  color: black;
  width: 35px;
}

.footer_socialMedia > a > .MuiSvgIcon-root:hover {
  color: var(--circlek-color);
  cursor: pointer;
}

.footer_copyRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_copyRight > .MuiSvgIcon-root {
  color: black;
  width: 20px;
}

.footer_copyRight > p {
  color: black;
  font-size: 14px;
}

.footer_image {
  width: 90%;
}

.home_masthead {
  display: block;
  margin: 100px auto 0;
  max-width: 100%;
  max-height: 600px;

}

.home_background {
  background-color: var(--lightest-circlek-color);
  padding: 40px 0 0;
}

.home_upper {
  margin: 0px auto;
  max-width: 90%;
  padding: 0 0 20px;
}


.home_propagandaBubble > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
}

.home_propagandaBubble > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 20px 0;
}

.home_mid {
  margin: 30px auto 0;
  max-width: 90%;
}

.home_mid > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 0 0 30px;
}

.home_mid > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 0 0 30px;
}

.home_family {
  display: flex;
}

.home_midLower {
  margin: 150px auto 0;
  max-width: 90%;
}

.home_midLower > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 0 0 30px;
}

.home_midLower > h3 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0;
}

.home_midLower > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  padding: 0 0 30px;
}

.home_midLowerS {
  border-bottom: 1px solid grey;
}

.home_tenents {
  margin: 30px auto 0;
  max-width: 90%;
}

.home_tenents > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
}

@media only screen and (min-width: 1024px) {
  .home_masthead {
    display: block;
    margin: 105px auto 0;
    max-width: 90%;
  }
  
  .home_background {
    background-color: var(--lightest-circlek-color);
    padding: 40px 0 0;
  }
  
  .home_upper {
    display: flex;
    margin: 0px auto;
    max-width: 90%;
    padding: 0 0 20px;
  }
  
  .home_propagandaBubble {
    flex: 0.64 1;
  }
  
  .home_propagandaBubble > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
  }
  
  .home_propagandaBubble > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 20px 0;
  }
  
  .home_propagandaVideo {
    flex: 0.36 1;
  }
  
  .home_mid {
    margin: 30px auto 0;
    max-width: 90%;
  }
  
  .home_mid > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 0 0 30px;
  }
  
  .home_mid > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 30px;
  }
  
  .home_family {
    display: flex;
  }
  
  .home_midLower {
    margin: 150px auto 0;
    max-width: 90%;
  }
  
  .home_midLower > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 0 0 30px;
  }
  
  .home_midLower > h3 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }
  
  .home_midLower > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    padding: 0 0 30px;
  }
  
  .home_midLowerS {
    border-bottom: 1px solid grey;
  }
  
  .home_tenents {
    margin: 30px auto 0;
    max-width: 90%;
  }
  
  .home_tenents > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
  }
}
.navbarOption {
  margin: 5px 2px;
  padding: 0 0 0px;
}

.navbarOption:hover {
  border-bottom: 2px solid var(--background-color);
  position: relative; 
  bottom: 2px;
  transition: all 0.3s ease-in-out;
}

.navbarOption > h1{
  padding: 10px 0 0;
  font-size: 22px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
}

@media only screen and (min-width: 768px) {
  .navbarOption {
    margin: 5px;
    padding: 0 0 0px;
  }
  
  .navbarOption:hover {
    border-bottom: 2px solid var(--background-color);
    position: relative; 
    bottom: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  .navbarOption > h1 {
    padding: 5px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
  }
}

h1 {
  display: block;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
} 
h2 {
  display: block;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
}

.navbar {
  background-color: var(--circlek-color);
  justify-content: center;
  height: 100px;
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  background-image: url(/static/media/Cloud.f85c4690.png);
  background-position: left top;
  background-size: contain;
  background-repeat: no-repeat; 
}

.navbar_inner {
  position: relative;
  top: 10px;
  margin: 0 auto;
  max-width: 1500px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height:50%;
}

.navbar_title {
  display: flex;
  align-items: baseline;
  position: relative;
}

.navbar_title > h1 {
  font-size: 50px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
  white-space: nowrap;
}

.navbar_title > h2 {
  margin: 0 0 0 10px;
  font-size: 22px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
  white-space: nowrap;
}

.navbar_lower {
  display: flex;
  align-items: center;
  position: relative;
  right: 5px;
  bottom: 10px;
}

@media only screen and (min-width: 1024px) {
  h1 {
    display: block;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
  } 
  h2 {
    display: block;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
    font-weight: bold;
  }
  .navbar {
    background-color: var(--circlek-color);
    justify-content: center;
    height: 100px;
    width: 100%;
    position: fixed;
    z-index: 100;
    top: 0;
    background-image: url(/static/media/Cloud.f85c4690.png);
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat; 
    padding-top: 6px;
  }
  
  .navbar_inner {
    position: relative;
    top: 10px;
    margin: 0 auto;
    max-width: 1500px;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
  }
  
  .navbar_title {
    display: flex;
    align-items: baseline;
    position: relative;
  }
  
  .navbar_title > h1 {
    font-size: 70px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
    white-space: nowrap;
  }
  
  .navbar_title > h2 {
    margin: 0 0 0 10px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
    white-space: nowrap;
  }
  
  .navbar_title:hover {
    border-bottom: 2px solid var(--background-color);
    position: relative; 
    bottom: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  
  .navbar_lower {
    display: flex;
    align-items: center;
    position: relative;
    right: 0px;
    bottom: 0px;
  }
}

.board_image {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  padding: 10px;
}

.board {
  padding: 20px 30px;
}

.board > h3 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 22px;
  color: black;
}

.board > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 18px;
  color: black;
}



@media only screen and (min-width: 768px) {
  .board_image {
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
}

.about {
  margin: 180px 0 0;
}


.about_upper {
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
  border-top: 5px solid var(--service-color);
  background-color: var(--light-accent-color);
}

.about_mission > h2 {
  font-family: var(--font-style);
  font-size: 24px;
  margin: 10px 0px;
}

.about_mission > p {
  font-family: var(--font-style);
  font-size: 18px;
  margin: 0px 0px 20px;
}

.about_overview > h2 {
  font-family: var(--font-style);
  font-size: 24px;
}

.about_overview > p {
  font-family: var(--font-style);
  font-size: 18px;
  margin: 10px 0px 20px;
}

.about_upperMid {
  border-radius: 5px;
  padding: 10px 20px;
  margin: 10px 0px;
  border-top: 5px solid var(--leadership-color);
  background-color: var(--light-accent-color);
}

.about_upperMid > h2 {
  padding: 20px;
  font-family: var(--font-style);
  font-size: 24px;
  text-align: center;
}

.about_upperMid > p {
  padding: 0px 0px 20px;
  font-family: var(--font-style);
  font-size: 18px;
}

.about_lowerMid {
  border-radius: 5px;
  padding: 10px 20px;
  margin: 20px 0px;
  border-top: 5px solid var(--fellowship-color);
  background-color: var(--light-accent-color);
}

.about_gbm {
  padding: 20px;
}

.about_gbm > h2 {
  font-family: var(--font-style);
  font-size: 24px;
  margin: 0px 0px 20px;
}

.about_gbm > p {
  font-family: var(--font-style);
  font-size: 18px;
}

.about_events {
  padding: 20px;
  text-align: center;
}

.about_events > h2 {
  font-family: var(--font-style);
  font-size: 24px;
}

.about_events > p {
  font-family: var(--font-style);
  font-size: 16px;
}

.about_themes {
  padding: 20px;
  text-align: center;
}

.about_themes > h2 {
  font-family: var(--font-style);
  font-size: 24px;
}

.about_themes > p {
  font-family: var(--font-style);
  font-size: 16px;
}

.about_kiwanis {
  flex: 0.3 1;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--light-accent-color);
  border-top: 5px solid var(--service-color);
}

.about_kiwanis > h2 {
  font-family: var(--font-style);
  font-size: 24px;
  padding: 20px 0px;
}

.about_kiwanis > p {
  font-family: var(--font-style);
  font-size: 18px;
  padding: 0px 0px 20px;
}

.about_executiveBoard {
  margin: 20px 0px 0px;
  text-align: center;
  border-radius: 5px;
  padding: 20px 0px;
  background-color: var(--light-accent-color);
  border-top: 5px solid var(--fellowship-color);
}

.about_executiveBoard > h2 {
  font-family: var(--font-style);
  font-size: 22px;
}

.about_executiveBoard h3 {
  font-family: var(--font-style);
  font-size: 20px;
}

.about_executiveBoard p {
  font-family: var(--font-style);
  font-size: 18px;
}

.about_appointedBoard {
  text-align: center;
  border-radius: 5px;
  padding: 20px 0px;
  margin: 20px 0px;
  background-color: var(--light-accent-color);
  border-top: 5px solid var(--leadership-color);
}

.about_appointedBoard > h2 {
  padding-bottom: 20px;
  font-family: var(--font-style);
  font-size: 22px;
}

.about_appointedBoard h3 {
  font-family: var(--font-style);
  font-size: 20px;
}

.about_appointedBoard p {
  font-family: var(--font-style);
  font-size: 18px;
}

@media only screen and (min-width: 1024px) {
  .about_upper {
    display: flex;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto 0px;
    max-width: 90%;
    background-color: var(--light-accent-color);
  }

  .about_mission {
    flex: 0.3 1;
    padding: 20px;
    border-right: 1px solid black;
  }

  .about_mission > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_mission > p {
    font-family: var(--font-style);
    font-size: 18px;
  }

  .about_overview {
    flex: 0.7 1;
    padding: 20px;
  }

  .about_overview > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_overview > p {
    font-family: var(--font-style);
    font-size: 18px;
  }

  .about_upperMid {
    display: flex;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto 0px;
    max-width: 90%;
    background-color: var(--light-accent-color);
  }

  .about_upperMid > h2 {
    flex: 0.07 1;
    padding: 20px;
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_upperMid > p {
    flex: 0.93 1;
    padding: 10px;
    font-family: var(--font-style);
    font-size: 18px;
  }

  .about_lowerMid {
    display: flex;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto 0px;
    max-width: 90%;
    background-color: var(--light-accent-color);
  }

  .about_gbm {
    flex: 0.4 1;
    padding: 20px;
    border-right: 1px solid black;
  }

  .about_gbm > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_gbm > p {
    font-family: var(--font-style);
    font-size: 18px;
  }

  .about_events {
    flex: 0.3 1;
    padding: 20px;
    text-align: center;
  }

  .about_events > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_events > p {
    font-family: var(--font-style);
    font-size: 16px;
  }

  .about_themes {
    flex: 0.3 1;
    padding: 20px;
    text-align: center;
  }

  .about_themes > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_themes > p {
    font-family: var(--font-style);
    font-size: 16px;
  }

  .about_lower {
    display: flex;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto 0px;
    max-width: 90%;
    background-color: var(--light-accent-color);
  }

  .about_kiwanis {
    flex: 0.3 1;
    padding: 10px 20px;
    max-width: 90%;
    border-right: 1px solid black;
  }

  .about_kiwanis > h2 {
    font-family: var(--font-style);
    font-size: 24px;
  }

  .about_kiwanis > p {
    font-family: var(--font-style);
    font-size: 16px;
  }

  .about_executiveBoard {
    flex: 0.7 1;
    text-align: center;
  }

  .about_executiveBoard > h2 {
    font-family: var(--font-style);
    font-size: 22px;
  }

  .about_executiveBoard h3 {
    font-family: var(--font-style);
    font-size: 20px;
  }

  .about_executiveBoardRow1 {
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
  }

  .about_executiveBoardRow2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0px 200px;
  }

  .about_appointedBoard {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px auto 0px;
    max-width: 90%;
    background-color: var(--light-accent-color);
  }

  .about_appointedBoard > h2 {
    padding-bottom: 20px;
    font-family: var(--font-style);
    font-size: 22px;
  }

  .about_appointedBoard h3 {
    font-family: var(--font-style);
    font-size: 20px;
  }

  .about_appointedBoard p {
    font-family: var(--font-style);
    font-size: 16px;
  }

  .about_appointedBoardRow1 {
    display: flex;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
  }

  .about_appointedBoardRow2 {
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
    padding-left: 100px;
  }
}

.modal_styles {
  border-radius: 3px;
  width: 75%;
}

.modal_upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 20px 2px;
}

.modal_upper > h1 {
  font-family: var(--font-form);
  text-align: left;
}

.modal_styles > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 20px 30px;
  font-size: 20px;
  font-weight: 500;
}

.modal_closeIcon {
  color: #D93A32;
  transform: scale(1.5);
}

.modal_closeIcon:hover {
  cursor: pointer;
  color: #FF6961;
}

.modal_rsvp1 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 20px 20px 2px;
  font-size: 22px;
  font-weight: 500;
}

.modal_rsvp2 {
  display: flex;
  align-items: center;
}

.modal_rsvp2 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px 20px;
  font-size: 22px;
}

.modal_rsvp2 > h3 {
  font-family: var(--font-form);
  font-size: 22px;
  margin-bottom: 8px;
  font-weight: 500;
}

.modal_styles > p {
  font-family: var(--font-form);
  font-size: 18px;
  text-align: left;
  font-weight: 500;
  margin: 0 20px 10px;
}

.modal_rsvp3 {
  display: flex;
  margin-left: 18px;
  align-items: baseline;
}

.modal_rsvp3 > h2 {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px;
  font-size: 22px;
}

.modal_rsvp3 > p {
  font-family: var(--font-form);
  text-align: left;
  margin: 0 5px 10px;
  font-size: 20px;
  font-weight: 500;
}

.LoggedIn {
  background-color: var(--light-circlek-color);
  color: white;
  border: none;
  border-radius: 17px;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-form);
  margin: 0 10px;
}

.LoggedIn:hover {
  background-color: var(--circlek-color);
  cursor: pointer;
}


.notLoggedIn {
  background-color: light-grey;
  border: none;
  border-radius: 17px;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 800;
  pointer-events: none;
  font-family: var(--font-form);
  margin: 0 10px;
}

@media only screen and (min-width: 1024px) {
  .modal_styles {
    border-radius: 3px;
    width: 1100px;
  }
  
  .modal_upper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px 2px;
  }
  
  .modal_upper > h1 {
    font-family: var(--font-form);
    text-align: left;
  }
  
  .modal_styles > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 20px 30px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .modal_closeIcon {
    color: #D93A32;
    transform: scale(1.5);
  }
  
  .modal_closeIcon:hover {
    cursor: pointer;
    color: #FF6961;
  }
  
  .modal_rsvp1 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 20px 20px 2px;
    font-size: 22px;
    font-weight: 500;
  }
  
  .modal_rsvp2 {
    display: flex;
    align-items: center;
  }
  
  .modal_rsvp2 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px 20px;
    font-size: 22px;
  }
  
  .modal_rsvp2 > h3 {
    font-family: var(--font-form);
    font-size: 22px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  
  .modal_styles > p {
    font-family: var(--font-form);
    font-size: 18px;
    text-align: left;
    font-weight: 500;
    margin: 0 20px 10px;
  }
  
  .modal_rsvp3 {
    display: flex;
    margin-left: 18px;
    align-items: baseline;
  }
  
  .modal_rsvp3 > h2 {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px;
    font-size: 22px;
  }
  
  .modal_rsvp3 > p {
    font-family: var(--font-form);
    text-align: left;
    margin: 0 5px 10px;
    font-size: 20px;
    font-weight: 500;
  }
  
  .LoggedIn {
    background-color: var(--light-circlek-color);
    color: white;
    border: none;
    border-radius: 17px;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-form);
  }
  
  .LoggedIn:hover {
    background-color: var(--circlek-color);
    cursor: pointer;
  }
  
  
  .notLoggedIn {
    background-color: light-grey;
    border: none;
    border-radius: 17px;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 800;
    pointer-events: none;
    font-family: var(--font-form);
  }
}
.service>.entryEvent>button {
  background-color: var(--service-color);
}

.social>.entryEvent>button {
  background-color: var(--social-color);
}

.committee>.entryEvent>button {
  background-color: var(--committee-color);
}

.kiwanis>.entryEvent>button {
  background-color: var(--kiwanis-color);
}

.fundraising>.entryEvent>button {
  background-color: var(--fundraising-color);
}

.division>.entryEvent>button {
  background-color: var(--division-color);
}

.leadership>.entryEvent>button {
  background-color: var(--leadership-color);
}

.entryEvent_button {
  border: none;
  border-radius: 7px;
  font-size: 16px;
  width: 180px;
  font-family: var(--font-form);
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.entryEvent_button:hover {
  cursor: pointer;
  color: grey;
}

/*@media only screen and (min-width: 768px) {
  .service > .entryEvent > button {
    background-color: var(--service-color);
  }
  .social > .entryEvent > button {
    background-color: var(--social-color);
  }
  .committee > .entryEvent > button {
    background-color: var(--committee-color);
  }
  .kiwanis > .entryEvent > button {
    background-color: var(--kiwanis-color);
  }
  .fundraising > .entryEvent > button {
    background-color: var(--fundraising-color);
  }
  .division > .entryEvent > button {
    background-color: var(--division-color);
  }

  .entryEvent > button {
    border: none;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 16px;
    max-width: 200px;
    font-family: var(--font-style)
  }

  .entryEvent > button:hover {
    cursor: pointer;
    color: grey;
  }
}
*/
.entry {
  margin-top: -10px;
  height: 100px;
}

.entry_list {
  height: 80px;
  display: relative;
  overflow-y: auto;
}

.entry_number {
  font-family: var(--font-form);
  font-size: 20px;
  text-align: right;
  padding-right: 5px;
  position: static;
}

/*@media only screen and (min-width: 768px) {
  .entry > p {
    font-family: var(--font-style);
    font-size: 16px;
    text-align: left;
    margin-left: 5px;
    margin-top: -55px;
    position: static;
  }
}*/

.calendarCogs_months {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 20px;
}

.calendarCogs_months > h1{
  color: var(--circlek-color);
  font-family: var(--font-form);
  font-size: 25px;
  font-weight: 800;
}

.calendarCogs_monthIcon_left {
  position: relative;
  right: 10px;
  background-color: var(--light-circlek-color);
  border-radius: 50% !important;
  color: white;
  transform: scale(1.5);
  margin-top: 4px;
}

.calendarCogs_monthIcon_right {
  background-color: var(--light-circlek-color);
  border-radius: 50% !important;
  color: white;
  transform: scale(1.5);
  margin-left: 20px;
  position: relative;
  right: 10px;
  font-weight: 1000;
}

.calendarCogs_monthIcon_left:hover {
  cursor: pointer;
  background-color: var(--circlek-color);
}

.calendarCogs_monthIcon_right:hover {
  cursor: pointer;
  background-color: var(--circlek-color);
}

.calendarCogs_calendar {
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 10px 0;
}

.calendarCogs_days {
  overflow: hidden;
  color: white;
  background-color: var(--light-circlek-color);
  border-radius: 15px 15px 0 0;
  line-height: 40px;
  font-family: var(--font-form);
  font-size: 16px;
  font-weight: 500;
}

.calendarCogs_week {
  height: 120px;
}

.calendarCogs_week > th {
  min-width: 14%;
  background-color: white;
  border-bottom: 2px solid black;
}


@media only screen and (min-width: 1024px) {
  .calendarCogs_months {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    width: 1350px;
    margin: 0 auto 20px;
  }
  
  .calendarCogs_months > h1{
    color: var(--circlek-color);
    font-family: var(--font-form);
    font-size: 70px;
    font-weight: 800;
  }
  
  .calendarCogs_monthIcon_left {
    position: relative;
    right: 10px;
    background-color: var(--light-circlek-color);
    border-radius: 50% !important;
    color: white;
    transform: scale(1.5);
    margin-top: 4px;
  }
  
  .calendarCogs_monthIcon_right {
    background-color: var(--light-circlek-color);
    border-radius: 50% !important;
    color: white;
    transform: scale(1.5);
    margin-left: 20px;
    position: relative;
    right: 10px;
    font-weight: 1000;
  }
  
  .calendarCogs_monthIcon_left:hover {
    cursor: pointer;
    background-color: var(--circlek-color);
  }
  
  .calendarCogs_monthIcon_right:hover {
    cursor: pointer;
    background-color: var(--circlek-color);
  }
  
  .calendarCogs_calendar {
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 10px 0;
  }
  
  .calendarCogs_days {
    overflow: hidden;
    color: white;
    background-color: var(--light-circlek-color);
    border-radius: 15px 15px 0 0;
    line-height: 40px;
    font-family: var(--font-form);
    font-size: 20px;
    font-weight: 500;
  }
  
  .calendarCogs_week {
    height: 120px;
  }
  
  .calendarCogs_week > th {
    min-width: 180px;
    background-color: white;
    border-bottom: 2px solid black;
  }
}


.calendarInput > h2 {
  font-family: var(--font-form);
  font-size: 22px;
  margin: 10px;
  
}


.calendarInput_form {
  display: flex;
  flex-direction: column;
}

.calendarInput_time {
  display: flex;
}

.calendarInput_time > h2 {
  font-family: var(--font-form);
  font-size: 16px;
  margin: 10px 10px 10px 0;
  background-color: white;
  border-radius: 5px;
  flex: 0.2 1;
  padding: 10px;
}

.calendarInput_time input {
  flex: 0.8 1;
}

.input {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  padding: 10px;
  font-family: var(--font-form);
}

.input_large {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  height: 200px;
  padding: 10px;
  font-family: var(--font-form);
  resize: none;
}

.calendarInput_form > button {
  background-color: var(--lighter-circlek-color);
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
}

.calendarInput_form > button:hover {
  background-color: var(--light-circlek-color);
  cursor: pointer;
  color: white;
}

.modal_styles1 {
  max-height: 90%;
  width: 80%;
  border-radius: 10px;
}

.modal_closeIcon1 {
  color: #D93A32;
  transform: scale(1.5);
}

.modal_styles1 > h2 {
  font-family: var(--font-form);
  font-size: 28px;
  margin: 0 0 10px;
  text-align: center;
}

.modal_closeIcon1:hover {
  cursor: pointer;
  color: #FF6961;
}

.calendarInput_form1 {
  display: flex;
  flex-direction: column;
  background-color: var(--lightest-circlek-color);
  padding: 25px;
  border-radius: 20px;
}

.calendarInput_time1 {
  display: flex;
}

.calendarInput_time1 > h2 {
  font-family: var(--font-form);
  font-size: 20px;
  margin: 10px 10px 10px 0;
  background-color: white;
  border-radius: 5px;
  flex: 0.2 1;
  padding: 10px;
}

.calendarInput_time1 input {
  flex: 0.8 1;
}

.input1 {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  padding: 10px;
  font-family: var(--font-form);
  background-color: white;
}

.input_large1 {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  height: 130px;
  padding: 10px;
  font-family: var(--font-form);
  background-color: white;
}

.calendarInput_form1 > button {
  background-color: var(--lighter-circlek-color);
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
}

.calendarInput_form1 > button:hover {
  background-color: var(--light-circlek-color);
  cursor: pointer;
  color: white;
}

@media only screen and (min-width: 1024px) {
  .modal_styles1 {
    max-height: 90%;
    width: 800px;
    border-radius: 10px;
  }
  
  .modal_closeIcon1 {
    color: #D93A32;
    transform: scale(1.5);
    position: relative;
    left: 770px;
  }
  
  .modal_styles1 > h2 {
    font-family: var(--font-form);
    font-size: 28px;
    margin: 0 0 10px;
    text-align: center;
  }
  
  .modal_closeIcon1:hover {
    cursor: pointer;
    color: #FF6961;
  }
  
  .calendarInput_form1 {
    display: flex;
    flex-direction: column;
    background-color: var(--lightest-circlek-color);
    padding: 25px;
    border-radius: 20px;
  }
  
  .calendarInput_time1 {
    display: flex;
  }
  
  .calendarInput_time1 > h2 {
    font-family: var(--font-form);
    font-size: 20px;
    margin: 10px 10px 10px 0;
    background-color: white;
    border-radius: 5px;
    flex: 0.2 1;
    padding: 10px;
  }
  
  .calendarInput_time1 input {
    flex: 0.8 1;
  }
  
  .input1 {
    border-radius: 5px;
    border: none;
    margin: 5px 0px;
    padding: 10px;
    font-family: var(--font-form);
    background-color: white;
  }
  
  .input_large1 {
    border-radius: 5px;
    border: none;
    margin: 5px 0px;
    height: 130px;
    padding: 10px;
    font-family: var(--font-form);
    background-color: white;
  }
  
  .calendarInput_form1 > button {
    background-color: var(--lighter-circlek-color);
    font-family: var(--font-form);
    font-size: 22px;
    font-weight: 700;
    height: 40px;
    margin: 10px 0;
    border-radius: 25px;
    border: none;
  }
  
  .calendarInput_form1 > button:hover {
    background-color: var(--light-circlek-color);
    cursor: pointer;
    color: white;
  }
}

.service1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--service-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.service1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.service1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.service1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--service-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.service1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}

.social1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--social-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.social1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.social1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.social1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--social-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.social1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}


.committee1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--committee-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.committee1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.committee1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.committee1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--committee-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.committee1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}

.kiwanis1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--kiwanis-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.kiwanis1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.kiwanis1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.kiwanis1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--kiwanis-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.kiwanis1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}

.fundraising1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--fundraising-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.fundraising1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.fundraising1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.fundraising1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--fundraising-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.fundraising1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}


.division1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--division-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.division1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.division1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.division1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--division-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.division1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}

.leadership1 {
  background-color: var(--lightest-circlek-color);
  border-top: 5px solid var(--leadership-color);
  border-radius: 15px;
  margin: 20px 0px;
  padding: 20px;
}

.leadership1 > p {
  font-family: var(--font-form);
  font-size: 18px;
  margin: 2px 0;
}

.leadership1 > h1 {
  font-family: var(--font-form);
  font-size: 24px;
  margin: 0 0 10px;
}

.leadership1 > .button_class > button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0px 0px;
  background-color: var(--leadership-color);
  font-family: var(--font-form);
  font-size: 18px;
}

.leadership1 > .button_class > button:hover {
  font-weight: bold;
  cursor: pointer;
}

.loginC_total {
  display: flex;
}

.loginC_signout {
  flex: 0.5 1;
  margin: 0 10px 0 120px;
  padding: 25px;
  background-color: var(--lightest-circlek-color);
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.loginC button {
  border-radius: 10px;
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 500;
  border: none;
}

.loginC_signout > h2 {
  font-family: var(--font-form);
}

.loginC_signout > img {
  width: 150px;
  display: block;
  margin: 10px auto;
  border-radius: 50%;
}

.loginC_signout > button {
  background-color: var(--lighter-circlek-color);
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
}

.loginC_signout > button:hover {
  background-color: var(--light-circlek-color);
  cursor: pointer;
  color: white;
}

.loginC_entry {
  flex: 0.5 1;
  margin: 0 120px 0 10px;
  padding: 25px;
}

.loginC_entry > h1 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 36px;
  color: var(--circlek-color);
}

.calendar {
  margin: 110px auto 0;
  width: 95%;
}

.calendar_categories {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 125px auto 100px;
}

.calendar_categories > h1 {
  border-radius: 50px;
  padding: 30px 20px;
  min-width: 140px;
  font-size: 27px;
  text-align: center;
  font-family: var(--font-form);
}

.calendar_categoriesService {
  background-color: var(--service-color);
}

.calendar_categoriesSocial {
  background-color: var(--social-color);
}

.calendar_categoriesCommittee {
  background-color: var(--committee-color);
}

.calendar_categoriesKiwanis {
  background-color: var(--kiwanis-color);
}

.calendar_categoriesFundraising {
  background-color: var(--fundraising-color);
}

.calendar_categoriesDivision {
  background-color: var(--division-color);
}

.calendar_categoriesLeadership {
  background-color: var(--leadership-color);
}

.calendar_profile {
  margin: 50px 0;
}

@media only screen and (min-width: 1024px) {
  .calendar {
    margin: 175px auto 0;
    max-width: 1920px;
    width: 95%;
  }
  
  .calendar_categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1350px;
    margin: 125px auto 100px;
  }
  
  .calendar_categories > h1 {
    border-radius: 50px;
    padding: 30px 20px;
    min-width: 140px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
  }
  
  .calendar_categoriesService {
    background-color: var(--service-color);
  }
  
  .calendar_categoriesSocial {
    background-color: var(--social-color);
  }
  
  .calendar_categoriesCommittee {
    background-color: var(--committee-color);
  }
  
  .calendar_categoriesKiwanis {
    background-color: var(--kiwanis-color);
  }
  
  .calendar_categoriesFundraising {
    background-color: var(--fundraising-color);
  }
  
  .calendar_categroiesDivision {
    background-color: var(--division-color);
  }

  .calendar_categoriesLeadership {
    background-color: var(--leadership-color);
  }
  
  .calendar_profile {
    margin: 50px 0;
  }
}

.account {
  margin: 110px auto 0;
  max-width: 95%;
}

.account > h2 {
  font-family: 'Oswald', sans-serif;
}

.account_title {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 50px 0 10px;
}

.account_upper {
  border-radius: 20px;
  background-color: var(--lightest-circlek-color);
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.account_upperService {
  padding: 30px;
  flex: 0.5 1;
  text-align: center;
}

.account_upperService > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
} 

.account_upperService > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_upperService button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_upperService button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_upperGBM {
  padding: 30px;
  flex: 0.5 1;
  text-align: center;
}

.account_upperGBM > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
} 

.account_upperGBM > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_upperGBM button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_upperGBM button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_mid {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
}

.account_families {
  text-align: center;
  padding: 30px;
  flex: 0.5 1;
}

.account_families > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
}

.account_families > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_bigLittle {
  text-align: center;
  padding: 30px;
  flex: 0.5 1;
}

.account_bigLittle > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
}

.account_bigLittle > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_bigLittle button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_bigLittle button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_presentFamilies {
  background-color: var(--lightest-circlek-color);
  padding: 30px 30px 80px;
  border-radius: 20px;  
}

.account_presentFamilies > h2 {
  text-align: center;
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
  margin: 0 0 20px;
}

.account_lower {
  background-color: var(--lightest-circlek-color);
  padding: 30px;
  margin: 30px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.account > h3 {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
}

.account_lower h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
  margin: 20px;
}

.account_lower p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  min-height: 200px;
}

.account_lower button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_lower button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_lowerApp {
  flex: 0.33 1;
  text-align: center;
  margin: 20px;
}

.account_lowerMembership {
  flex: 0.34 1;
  text-align: center;
  margin: 20px;
}

.account_lowerCST {
  flex: 0.33 1;
  text-align: center;
  margin: 20px;
}

.account_ready > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0;
}

.account_ready > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

@media only screen and (min-width: 1024px) {
  .account {
    margin: 150px auto 0;
    max-width: 90%;
    min-width: 1000px;
  }
  
  .account_title {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 50px 0 10px;
  }
  
  .account_upper {
    border-radius: 20px;
    background-color: var(--lightest-circlek-color);
    display: flex;
    flex-direction: row;
    margin: 20px 0 0;
  }
  
  .account_upperService {
    padding: 30px;
    flex: 0.5 1;
    text-align: center;
  }
  
  .account_upperService > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  } 
  
  .account_upperService > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_upperService button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_upperService button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_upperGBM {
    padding: 30px;
    flex: 0.5 1;
    text-align: center;
  }
  
  .account_upperGBM > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  } 
  
  .account_upperGBM > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_upperGBM button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_upperGBM button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_mid {
    display: flex;
    flex-direction: row;
    margin: 0 0 30px;
  }
  
  .account_families {
    text-align: center;
    padding: 30px;
    flex: 0.5 1;
  }
  
  .account_families > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  }
  
  .account_families > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_bigLittle {
    text-align: center;
    padding: 30px;
    flex: 0.5 1;
  }
  
  .account_bigLittle > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  }
  
  .account_bigLittle > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  /*_____________*/
  .account_bigLittle button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }

  .account_bigLittle button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_presentFamilies {
    background-color: var(--lightest-circlek-color);
    padding: 30px 30px 80px;
    border-radius: 20px;  
  }
  
  .account_presentFamilies > h2 {
    text-align: center;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
    margin: 0 0 20px;
  }
  
  .account_lower {
    background-color: var(--lightest-circlek-color);
    padding: 30px;
    margin: 30px 0;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .account > h3 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_lower h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
    margin: 20px;
  }
  
  .account_lower p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    min-height: 200px;
  }
  
  .account_lower button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_lower button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_lowerApp {
    flex: 0.33 1;
    text-align: center;
    margin: 20px;
  }
  
  .account_lowerMembership {
    flex: 0.34 1;
    text-align: center;
    margin: 20px;
  }
  
  .account_lowerCST {
    flex: 0.33 1;
    text-align: center;
    margin: 20px;
  }
  
  .account_ready > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }
  
  .account_ready > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
}
.login {
    width: 300px; 
    /* position: fixed; */
  }

  .login button {
    border-radius: 10px;
    font-family: var(--font-form);
    font-size: 22px;
    font-weight: 500;
    border: none;
  }
  
  .login_signout {
    margin: 20px;
    padding: 10px;
    position: relative;
    right: 12px;
    display: flex;
    flex-direction: column;
    background-color: var(--lightest-circlek-color);
    border-radius: 20px;
    width: 340px;
    text-align: center;
  }
  
  .login_signout > h2 {
    margin: 20px 0px 10px;
    font-family: var(--font-form);
    font-size: 28px;
    font-weight: 700;
  }
  
  .login_signout > img {
    width: 150px;
    display: block;
    margin: 0px auto;
    border-radius: 50%;
  }
  
  .login_signout > h3 {
    margin-top: 5px;
    font-family: var(--font-form);
    font-size: 22px;
    font-weight: 700;
  }
  
  .login_signout > button {
    background-color: var(--lighter-circlek-color);
    font-family: var(--font-form);
    font-size: 22px;
    font-weight: 700;
    margin: 10px 0 0;
    height: 40px;
    border-radius: 20px;
  }

  .login_signout > button:hover {
    background-color: var(--light-circlek-color);
    cursor: pointer;
    color: white;
  }

  @media only screen and (min-width: 1024px) {
    .login {
      width: 300px; 
      /* position: fixed; */
    }
  
    .login button {
      border-radius: 10px;
      font-family: var(--font-form);
      font-size: 22px;
      font-weight: 500;
      border: none;
    }
    
    .login_signout {
      margin: 20px;
      padding: 10px;
      position: relative;
      right: 20px;
      display: flex;
      flex-direction: column;
      background-color: var(--lightest-circlek-color);
      border-radius: 20px;
      width: 340px;
      text-align: center;
    }
    
    .login_signout > h2 {
      margin: 20px 0px 10px;
      font-family: var(--font-form);
      font-size: 28px;
      font-weight: 700;
    }
    
    .login_signout > img {
      width: 150px;
      display: block;
      margin: 0px auto;
      border-radius: 50%;
    }
    
    .login_signout > h3 {
      margin-top: 5px;
      font-family: var(--font-form);
      font-size: 22px;
      font-weight: 700;
    }
    
    .login_signout > button {
      background-color: var(--lighter-circlek-color);
      font-family: var(--font-form);
      font-size: 22px;
      font-weight: 700;
      margin: 10px 0 0;
      height: 40px;
      border-radius: 20px;
    }
  
    .login_signout > button:hover {
      background-color: var(--light-circlek-color);
      cursor: pointer;
      color: white;
    }
  }
.lparent_upper {
    display: flex;
    max-width: 1000px;
    margin: 120px auto 110px;
    flex-direction: column;
}

.lparent_upperLogin {
    text-align: center;
    
}

.lparent_upperLogin > h1 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color);
}

.lparent_upperRight {
    background-color: var(--lightest-circlek-color);
    filter: alpha(opacity=50);
    border-radius: 20px;
    padding: 20px;
    flex: 0.6 1;
}

.lparent_upperRight > h1 {
    text-align: left;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
    color: black;
    padding: 20px;
}

.lparent_upperRight > p {
    text-align: left;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    color: var(black);
    padding: 0 20px 20px;
}

.lparent_button button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 0 20px 20px;
    padding: 20px;
    border-radius: 20px;
}

.lparent_button button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
}

@media only screen and (min-width: 1024px) {
    .lparent_upper {
        display: flex;
        max-width: 1000px;
        margin: 150px auto 110px;
        flex-direction: row;
    }
    
    .lparent_upperLogin {
        text-align: left;
        flex: 0.4 1;
    }
    
    .lparent_upperLogin > h1 {
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 36px;
        color: var(--circlek-color);
    }
    
    .lparent_upperRight {
        background-color: var(--lightest-circlek-color);
        filter: alpha(opacity=50);
        border-radius: 20px;
        padding: 20px;
        flex: 0.6 1;
    }
    
    .lparent_upperRight > h1 {
        text-align: left;
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 32px;
        color: black;
        padding: 20px;
    }
    
    .lparent_upperRight > p {
        text-align: left;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        color: var(black);
        padding: 0 20px 20px;
    }
    
    .lparent_button button {
        background-color: var(--lighter-circlek-color);
        border: none;
        font-family: var(--font-form);
        font-weight: 600;
        font-size: 22px;
        color: var(black);
        margin: 0 20px 20px;
        padding: 20px;
        border-radius: 20px;
    }
    
    .lparent_button button:hover {
        cursor: pointer;
        background-color: var(--light-circlek-color);
        color: white;
        transition: all 0.3s ease-in-out;
    }    
}
.aboutNavbar {
    display: flex;
    justify-content: center;
    height: 50px;
}

.aboutNavbar > h3 {
    text-align: left;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    color: black;
    padding: 0 2px;
}

.aboutNavbar h2 {
    text-align: left;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 22px;
    color: black;
    padding: 0 15px 5px;
}


@media only screen and (min-width: 1024px) {
    .aboutNavbar {
        display: flex;
        justify-content: center;
        height: 50px;
    }
    
    .aboutNavbar > h3 {
        text-align: left;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 32px;
        color: black;
        padding: 0 30px;
    }
    
    .aboutNavbar h2 {
        text-align: left;
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 32px;
        color: black;
        padding: 0 20px 5px;
    }
    
    .aboutNavbar h2:hover {
        border-bottom: 2px solid black;
        position: relative; 
        bottom: 2px;
        transition: all 0.3s ease-in-out;
    }
}
.general {
    margin: 150px 0 0;
}

.general_sub {
    margin: 0 auto 0;
    width: 90%;
    max-width: 1500px;
}

.general_text1 {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
}

.general_text1 > h2 {
    flex: 0.2 1;
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 5px 0;
}

.general_text1 > p {
    flex: 0.8 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.general_events {
    display: flex;
    margin: 20px;
    flex-direction: column;
}

.general_events > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    position: relative;
    margin: 0 80px 10px 0;
    top: 10px;
    right: 20px;
}

.general_eventsList > li {
    font-family: var(--font-form);
    margin: 10px;
    font-weight: 500;
    font-size: 22px;
    list-style: none;
}

.general_sub > h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 40px 0 20px;
}

.general_tenetSub {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.general_tenetSub > h2 {
    flex: 0.2 1;
    text-align: left;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    color: black;
    margin: 0 0 10px;
}

.general_tenetSub > p {
    flex: 0.8 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

@media only screen and (min-width: 1024px) {
    .general {
        margin: 150px 0 0;
    }
    
    .general_sub {
        margin: 0 auto 0;
        width: 90%;
        max-width: 1500px;
    }
    
    .general_text1 {
        display: flex;
        margin: 40px 0;
        flex-direction: row;
    }
    
    .general_text1 > h2 {
        flex: 0.2 1;
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
        margin: 0;
    }
    
    .general_text1 > p {
        flex: 0.8 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .general_events {
        display: flex;
        margin: 20px;
        flex-direction: row;
    }
    
    .general_events > h2 {
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
        position: relative;
        margin: 0 80px 0 0;
        top: 10px;
        right: 20px;
    
    }
    
    .general_eventsList > li {
        font-family: var(--font-form);
        margin: 10px 20px;
        font-weight: 500;
        font-size: 22px;
        list-style: none;
    }
    
    .general_sub > h1 {
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
        margin: 40px 0 20px;
    }
    
    .general_tenetSub {
        display: flex;
        flex-direction: row;
        margin: 20px 0;
    }
    
    .general_tenetSub > h2 {
        flex: 0.2 1;
        text-align: left;
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 28px;
        color: black;
        margin: 0;
    }
    
    .general_tenetSub > p {
        flex: 0.8 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
}
.ourClub {
    margin: 150px 0 0;
}

.ourClub_sub {
    margin: 0 auto 0;
    width: 90%;
    max-width: 1500px;
}

.ourClub_text1 {
    display: flex;
    margin: 40px 0;
    flex-direction: column;
}

.ourClub_text1 > h2 {
    flex: 0.25 1;
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 0 0 10px;
}

.ourClub_text1 > p {
    flex: 0.75 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.ourClub_events {
    display: flex;
    margin: 40px 0;
    flex-direction: column;
}

.ourClub_events > h2 {
    flex: 0.25 1;
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
}

.ourClub_events > div {
    flex: 0.85 1;
}

.ourClub_rows {
    display: flex;
    flex-direction: column;
}

.ourClub_rows > p {
    flex: 0.5 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 5px 30px;
}

.ourClub_events > ul {
    flex: 0.375 1;
    list-style: none;
    position: relative;
    right: 20px;
    top: 10px;
}

.ourClub_events > ul > li {
    flex: 0.5 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.ourClub_text1 > div {
    flex: 0.75 1;
}

.ourClub_text1 > div > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 30px;
}

.ourClub_text1 > div > h3 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.ourClub_board {
    text-align: center;
    padding: 30px 0;
    background-color: var(--lightest-circlek-color);
}

.ourClub_board > h2 {
    font-family: var(--font-form);
    font-size: 32px;
    color: black;
}

.ourClub_board > h3 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    color: black;
    margin: 30px 0;
}

.ourClub_execBoard {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ourClub_appointedBoard1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ourClub_appointedBoard2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (min-width: 1024px) {
    .ourClub {
        margin: 150px 0 0;
    }
    
    .ourClub_sub {
        margin: 0 auto 0;
        width: 90%;
        max-width: 1500px;
    }
    
    .ourClub_text1 {
        display: flex;
        margin: 40px 0;
        flex-direction: row;
    }
    
    .ourClub_text1 > h2 {
        flex: 0.25 1;
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
        margin: 0;
    }
    
    .ourClub_text1 > p {
        flex: 0.75 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .ourClub_events {
        display: flex;
        margin: 40px 0;
        flex-direction: row;
    }
    
    .ourClub_events > h2 {
        flex: 0.25 1;
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
    }
    
    .ourClub_events > div {
        flex: 0.85 1;
    }
    
    .ourClub_rows {
        display: flex;
        flex-direction: row;
    }
    
    .ourClub_rows > p {
        flex: 0.5 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 5px 30px;
    }
    
    .ourClub_events > ul {
        flex: 0.375 1;
        list-style: none;
        position: relative;
        right: 20px;
        top: 0;
    }
    
    .ourClub_events > ul > li {
        flex: 0.5 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .ourClub_text1 > div {
        flex: 0.75 1;
    }
    
    .ourClub_text1 > div > p {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 0 0 30px;
    }
    
    .ourClub_text1 > div > h3 {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .ourClub_board {
        text-align: center;
        padding: 30px 0;
        background-color: var(--lightest-circlek-color);
    }
    
    .ourClub_board > h2 {
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 32px;
        color: black;
    }
    
    .ourClub_board > h3 {
        font-family: var(--font-form);
        font-weight: 900;
        font-size: 28px;
        color: black;
        margin: 30px 0;
    }
    
    .ourClub_execBoard {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
    .ourClub_appointedBoard1 {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
    .ourClub_appointedBoard2 {
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    
}
.structure {
    margin: 150px 0 0;
}

.structure_sub {
    margin: 0 auto 0;
    width: 90%;
    max-width: 1500px;
}

.structure_text1 {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
}

.structure_text1 > h2 {
    flex: 0.2 1;
    margin: 10px 0;
    font-family: 'Oswald', sans-serif;
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color);
}

.structure_text1 > p {
    flex: 0.8 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.structure_text1 > div {
    flex: 0.8 1;
}

.structure_text1 > div > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 40px;
}

.structure_text1 > div > h3 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.structure_district {
    display: flex;
}

.structure_district > h2 {
    flex: 0.3 1;
    font-family: 'Oswald', sans-serif;
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color);
}

.structure_district > ul {
    flex: 0.7 1;
    list-style: none;
}

.structure_district > ul > li {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
}

.structure_clubs {
    display: flex;
    flex-direction: column;
}

.structure_clubs > h2 {
    flex: 0.3 1;
    font-family: 'Oswald', sans-serif;
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color)
}

.structure_list {
    flex: 0.7 1;
}


.structure_row1 {
    display: flex;
} 

.structure_row1 > h2 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 10px 2px;
    flex: 0.33 1;
}

.structure_row2 {
    display: flex;
}

.structure_row2 > h2 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 2px;
    flex: 0.33 1;
}

.structure_row3 {
    display: flex;
}

.structure_row3 > h2 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 10px;
    flex: 0.33 1;
}

@media only screen and (min-width: 1024px) {
    .structure {
        margin: 150px 0 0;
    }
    
    .structure_sub {
        margin: 0 auto 0;
        width: 90%;
        max-width: 1500px;
    }
    
    .structure_text1 {
        display: flex;
        flex-direction: row;
        margin: 40px 0;
    }
    
    .structure_text1 > h2 {
        flex: 0.2 1;
        margin: 0;
        font-family: 'Oswald', sans-serif;
        font-size: 36px;
        color: var(--circlek-color);
    }
    
    .structure_text1 > p {
        flex: 0.8 1;
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .structure_text1 > div {
        flex: 0.8 1;
    }
    
    .structure_text1 > div > p {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 0 0 40px;
    }
    
    .structure_text1 > div > h3 {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .structure_district {
        display: flex;
    }
    
    .structure_district > h2 {
        flex: 0.3 1;
        font-family: 'Oswald', sans-serif;
        font-weight: 900;
        font-size: 36px;
        color: var(--circlek-color);
    }
    
    .structure_district > ul {
        flex: 0.7 1;
        list-style: none;
    }
    
    .structure_district > ul > li {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
    }
    
    .structure_clubs {
        display: flex;
        flex-direction: column;
    }
    
    .structure_clubs > h2 {
        flex: 0.3 1;
        font-family: 'Oswald', sans-serif;
        font-weight: 900;
        font-size: 36px;
        color: var(--circlek-color)
    }
    
    .structure_list {
        flex: 0.7 1;
    }
    
    
    .structure_row1 {
        display: flex;
    } 
    
    .structure_row1 > h2 {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 10px;
        flex: 0.33 1;
    }
    
    .structure_row2 {
        display: flex;
    }
    
    .structure_row2 > h2 {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 10px;
        flex: 0.33 1;
    }
    
    .structure_row3 {
        display: flex;
    }
    
    .structure_row3 > h2 {
        font-family: var(--font-form);
        font-weight: 500;
        font-size: 22px;
        margin: 10px;
        flex: 0.33 1;
    }
}
body {
  --font-form: 'Comfortaa', cursive;
  --font-form-minor: 'Lato', cursive;
  --light-circlek-color: #4E6E93;
  --leadership-color: #FF715B;
  --service-color: #EDDE95;
  --committee-color: #B9DBE2;
  --social-color: #EFD8D8;
  --fundraising-color: #DBCFEB;
  --kiwanis-color: #B6D5C8;
  --division-color: #DAC6AF;
  --lighter-circlek-color: #B9DBE2;
  --lightest-circlek-color: hsl(190, 41%, 81%, 0.25);



  --font-style: 'EB Garamond', serif;
  --leadership-color: #FF715B;
  --background-color: #fffcf9;
  --fellowship-color: #48BF84;
  --accent-color: #4C5454;
  --light-accent-color: #DDE1E4;
  --circlek-color: #174274;
  --dark-color: #191516;

}


