.footer {
  margin-top: 15px;
  text-align: center;
  background-image: url("./images/Black+Masthead.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat; 
  height: 200px;
}

.footer_socialMedia > a > .MuiSvgIcon-root {
  color: black;
  width: 35px;
}

.footer_socialMedia > a > .MuiSvgIcon-root:hover {
  color: var(--circlek-color);
  cursor: pointer;
}

.footer_copyRight {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_copyRight > .MuiSvgIcon-root {
  color: black;
  width: 20px;
}

.footer_copyRight > p {
  color: black;
  font-size: 14px;
}

.footer_image {
  width: 90%;
}
