.tenent {
  display: flex;
  flex-direction: column;
}

.tenent > h3 {
  text-align: center;
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0 0;
}

.tenent > p {
  z-index: 1;
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 18px 0;
  position: relative;
  top: 42px;
  text-align: center;
}

.Service[value] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Service[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Service[value]::-webkit-progress-value {
  background-color: #F0E189;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.Fellowship[value] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Fellowship[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Fellowship[value]::-webkit-progress-value {
  background-color: #199BC7;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.Leadership[value] {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  position: relative;
  bottom: 18px;
  margin: 0 auto;
  width: 100%;
  height: 60px;
}

.Leadership[value]::-webkit-progress-bar {
  background-color: var(--light-accent-color);
  border-radius: 30px;
}

.Leadership[value]::-webkit-progress-value {
  background-color: #F47D2C;
  border-radius: 30px 0 0 30px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.tenent_logo {
  flex: 0.20;
  max-width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 150px;
}

@media only screen and (min-width: 1024px) {
  .tenent {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 1300px;
  }

  .tenent > h3 {
    flex: 0.15;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }

  .tenent > p {
    flex: 0.15;
    z-index: 1;
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 18px 0;
    position: relative;
    top: 0;
    left: 500px;
  }

  progress {
    flex: 0.5;
    position: relative;
    top: 0px;
    right: 120px
  }

  .Service[value] {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Service[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Service[value]::-webkit-progress-value {
    background-color: #F0E189;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .Fellowship[value] {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Fellowship[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Fellowship[value]::-webkit-progress-value {
    background-color: #199BC7;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .Leadership[value] {
    -webkit-appearance: none;
    appearance: none;
    display: block;
    position: relative;
    bottom: 18px;
    margin: 0 auto;
    width: 100%;
    height: 60px;
  }

  .Leadership[value]::-webkit-progress-bar {
    background-color: var(--light-accent-color);
    border-radius: 30px;
  }

  .Leadership[value]::-webkit-progress-value {
    background-color: #F47D2C;
    border-radius: 30px 0 0 30px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }

  .tenent_logo {
    flex: 0.20;
    max-width: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 150px;
  }
}
