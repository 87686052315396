@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
.account {
  margin: 110px auto 0;
  max-width: 95%;
}

.account > h2 {
  font-family: 'Oswald', sans-serif;
}

.account_title {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 50px 0 10px;
}

.account_upper {
  border-radius: 20px;
  background-color: var(--lightest-circlek-color);
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.account_upperService {
  padding: 30px;
  flex: 0.5;
  text-align: center;
}

.account_upperService > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
} 

.account_upperService > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_upperService button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_upperService button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_upperGBM {
  padding: 30px;
  flex: 0.5;
  text-align: center;
}

.account_upperGBM > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
} 

.account_upperGBM > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_upperGBM button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_upperGBM button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_mid {
  display: flex;
  flex-direction: column;
  margin: 0 0 30px;
}

.account_families {
  text-align: center;
  padding: 30px;
  flex: 0.5;
}

.account_families > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
}

.account_families > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_bigLittle {
  text-align: center;
  padding: 30px;
  flex: 0.5;
}

.account_bigLittle > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
}

.account_bigLittle > p {
  font-family: var(--font-form);
  margin: 20px;
  font-weight: 500;
  font-size: 22px;
}

.account_bigLittle button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_bigLittle button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_presentFamilies {
  background-color: var(--lightest-circlek-color);
  padding: 30px 30px 80px;
  border-radius: 20px;  
}

.account_presentFamilies > h2 {
  text-align: center;
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
  margin: 0 0 20px;
}

.account_lower {
  background-color: var(--lightest-circlek-color);
  padding: 30px;
  margin: 30px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.account > h3 {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
}

.account_lower h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 32px;
  margin: 20px;
}

.account_lower p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  min-height: 200px;
}

.account_lower button {
  background-color: var(--lighter-circlek-color);
  border: none;
  font-family: var(--font-form);
  font-weight: 600;
  font-size: 22px;
  color: var(black);
  margin: 20px 20px 0;
  padding: 20px;
  border-radius: 20px;
}

.account_lower button:hover {
  cursor: pointer;
  background-color: var(--light-circlek-color);
  color: white;
  transition: all 0.3s ease-in-out;
}

.account_lowerApp {
  flex: 0.33;
  text-align: center;
  margin: 20px;
}

.account_lowerMembership {
  flex: 0.34;
  text-align: center;
  margin: 20px;
}

.account_lowerCST {
  flex: 0.33;
  text-align: center;
  margin: 20px;
}

.account_ready > h2 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0;
}

.account_ready > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
}

@media only screen and (min-width: 1024px) {
  .account {
    margin: 150px auto 0;
    max-width: 90%;
    min-width: 1000px;
  }
  
  .account_title {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 50px 0 10px;
  }
  
  .account_upper {
    border-radius: 20px;
    background-color: var(--lightest-circlek-color);
    display: flex;
    flex-direction: row;
    margin: 20px 0 0;
  }
  
  .account_upperService {
    padding: 30px;
    flex: 0.5;
    text-align: center;
  }
  
  .account_upperService > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  } 
  
  .account_upperService > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_upperService button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_upperService button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_upperGBM {
    padding: 30px;
    flex: 0.5;
    text-align: center;
  }
  
  .account_upperGBM > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  } 
  
  .account_upperGBM > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_upperGBM button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_upperGBM button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_mid {
    display: flex;
    flex-direction: row;
    margin: 0 0 30px;
  }
  
  .account_families {
    text-align: center;
    padding: 30px;
    flex: 0.5;
  }
  
  .account_families > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  }
  
  .account_families > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_bigLittle {
    text-align: center;
    padding: 30px;
    flex: 0.5;
  }
  
  .account_bigLittle > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
  }
  
  .account_bigLittle > p {
    font-family: var(--font-form);
    margin: 20px;
    font-weight: 500;
    font-size: 22px;
  }
  /*_____________*/
  .account_bigLittle button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }

  .account_bigLittle button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_presentFamilies {
    background-color: var(--lightest-circlek-color);
    padding: 30px 30px 80px;
    border-radius: 20px;  
  }
  
  .account_presentFamilies > h2 {
    text-align: center;
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
    margin: 0 0 20px;
  }
  
  .account_lower {
    background-color: var(--lightest-circlek-color);
    padding: 30px;
    margin: 30px 0;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
  }
  
  .account > h3 {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
  }
  
  .account_lower h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 32px;
    margin: 20px;
  }
  
  .account_lower p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    min-height: 200px;
  }
  
  .account_lower button {
    background-color: var(--lighter-circlek-color);
    border: none;
    font-family: var(--font-form);
    font-weight: 600;
    font-size: 22px;
    color: var(black);
    margin: 20px 20px 0;
    padding: 20px;
    border-radius: 20px;
  }
  
  .account_lower button:hover {
    cursor: pointer;
    background-color: var(--light-circlek-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }
  
  .account_lowerApp {
    flex: 0.33;
    text-align: center;
    margin: 20px;
  }
  
  .account_lowerMembership {
    flex: 0.34;
    text-align: center;
    margin: 20px;
  }
  
  .account_lowerCST {
    flex: 0.33;
    text-align: center;
    margin: 20px;
  }
  
  .account_ready > h2 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }
  
  .account_ready > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
}