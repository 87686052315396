body {
  --font-form: 'Comfortaa', cursive;
  --font-form-minor: 'Lato', cursive;
  --light-circlek-color: #4E6E93;
  --leadership-color: #FF715B;
  --service-color: #EDDE95;
  --committee-color: #B9DBE2;
  --social-color: #EFD8D8;
  --fundraising-color: #DBCFEB;
  --kiwanis-color: #B6D5C8;
  --division-color: #DAC6AF;
  --lighter-circlek-color: #B9DBE2;
  --lightest-circlek-color: hsl(190, 41%, 81%, 0.25);



  --font-style: 'EB Garamond', serif;
  --leadership-color: #FF715B;
  --background-color: #fffcf9;
  --fellowship-color: #48BF84;
  --accent-color: #4C5454;
  --light-accent-color: #DDE1E4;
  --circlek-color: #174274;
  --dark-color: #191516;

}

