.calendarInput > h2 {
  font-family: var(--font-form);
  font-size: 22px;
  margin: 10px;
  
}


.calendarInput_form {
  display: flex;
  flex-direction: column;
}

.calendarInput_time {
  display: flex;
}

.calendarInput_time > h2 {
  font-family: var(--font-form);
  font-size: 16px;
  margin: 10px 10px 10px 0;
  background-color: white;
  border-radius: 5px;
  flex: 0.2;
  padding: 10px;
}

.calendarInput_time input {
  flex: 0.8;
}

.input {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  padding: 10px;
  font-family: var(--font-form);
}

.input_large {
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  height: 200px;
  padding: 10px;
  font-family: var(--font-form);
  resize: none;
}

.calendarInput_form > button {
  background-color: var(--lighter-circlek-color);
  font-family: var(--font-form);
  font-size: 22px;
  font-weight: 700;
  height: 40px;
  margin: 10px 0;
  border-radius: 25px;
  border: none;
}

.calendarInput_form > button:hover {
  background-color: var(--light-circlek-color);
  cursor: pointer;
  color: white;
}
