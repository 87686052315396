@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
.home_masthead {
  display: block;
  margin: 100px auto 0;
  max-width: 100%;
  max-height: 600px;

}

.home_background {
  background-color: var(--lightest-circlek-color);
  padding: 40px 0 0;
}

.home_upper {
  margin: 0px auto;
  max-width: 90%;
  padding: 0 0 20px;
}


.home_propagandaBubble > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
}

.home_propagandaBubble > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 20px 0;
}

.home_mid {
  margin: 30px auto 0;
  max-width: 90%;
}

.home_mid > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 0 0 30px;
}

.home_mid > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  margin: 0 0 30px;
}

.home_family {
  display: flex;
}

.home_midLower {
  margin: 150px auto 0;
  max-width: 90%;
}

.home_midLower > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
  margin: 0 0 30px;
}

.home_midLower > h3 {
  font-family: var(--font-form);
  font-weight: 900;
  font-size: 28px;
  margin: 20px 0;
}

.home_midLower > p {
  font-family: var(--font-form);
  font-weight: 500;
  font-size: 22px;
  padding: 0 0 30px;
}

.home_midLowerS {
  border-bottom: 1px solid grey;
}

.home_tenents {
  margin: 30px auto 0;
  max-width: 90%;
}

.home_tenents > h2 {
  font-family: 'Oswald', sans-serif;
  font-size: 36px;
  color: var(--circlek-color);
}

@media only screen and (min-width: 1024px) {
  .home_masthead {
    display: block;
    margin: 105px auto 0;
    max-width: 90%;
  }
  
  .home_background {
    background-color: var(--lightest-circlek-color);
    padding: 40px 0 0;
  }
  
  .home_upper {
    display: flex;
    margin: 0px auto;
    max-width: 90%;
    padding: 0 0 20px;
  }
  
  .home_propagandaBubble {
    flex: 0.64;
  }
  
  .home_propagandaBubble > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
  }
  
  .home_propagandaBubble > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 20px 0;
  }
  
  .home_propagandaVideo {
    flex: 0.36;
  }
  
  .home_mid {
    margin: 30px auto 0;
    max-width: 90%;
  }
  
  .home_mid > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 0 0 30px;
  }
  
  .home_mid > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 30px;
  }
  
  .home_family {
    display: flex;
  }
  
  .home_midLower {
    margin: 150px auto 0;
    max-width: 90%;
  }
  
  .home_midLower > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
    margin: 0 0 30px;
  }
  
  .home_midLower > h3 {
    font-family: var(--font-form);
    font-weight: 900;
    font-size: 28px;
    margin: 20px 0;
  }
  
  .home_midLower > p {
    font-family: var(--font-form);
    font-weight: 500;
    font-size: 22px;
    padding: 0 0 30px;
  }
  
  .home_midLowerS {
    border-bottom: 1px solid grey;
  }
  
  .home_tenents {
    margin: 30px auto 0;
    max-width: 90%;
  }
  
  .home_tenents > h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 36px;
    color: var(--circlek-color);
  }
}