.service>.entryEvent>button {
  background-color: var(--service-color);
}

.social>.entryEvent>button {
  background-color: var(--social-color);
}

.committee>.entryEvent>button {
  background-color: var(--committee-color);
}

.kiwanis>.entryEvent>button {
  background-color: var(--kiwanis-color);
}

.fundraising>.entryEvent>button {
  background-color: var(--fundraising-color);
}

.division>.entryEvent>button {
  background-color: var(--division-color);
}

.leadership>.entryEvent>button {
  background-color: var(--leadership-color);
}

.entryEvent_button {
  border: none;
  border-radius: 7px;
  font-size: 16px;
  width: 180px;
  font-family: var(--font-form);
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

.entryEvent_button:hover {
  cursor: pointer;
  color: grey;
}

/*@media only screen and (min-width: 768px) {
  .service > .entryEvent > button {
    background-color: var(--service-color);
  }
  .social > .entryEvent > button {
    background-color: var(--social-color);
  }
  .committee > .entryEvent > button {
    background-color: var(--committee-color);
  }
  .kiwanis > .entryEvent > button {
    background-color: var(--kiwanis-color);
  }
  .fundraising > .entryEvent > button {
    background-color: var(--fundraising-color);
  }
  .division > .entryEvent > button {
    background-color: var(--division-color);
  }

  .entryEvent > button {
    border: none;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 5px;
    font-size: 16px;
    max-width: 200px;
    font-family: var(--font-style)
  }

  .entryEvent > button:hover {
    cursor: pointer;
    color: grey;
  }
}
*/