.navbarOption {
  margin: 5px 2px;
  padding: 0 0 0px;
}

.navbarOption:hover {
  border-bottom: 2px solid var(--background-color);
  position: relative; 
  bottom: 2px;
  transition: all 0.3s ease-in-out;
}

.navbarOption > h1{
  padding: 10px 0 0;
  font-size: 22px;
  text-align: center;
  font-family: var(--font-form);
  color: var(--background-color);
}

@media only screen and (min-width: 768px) {
  .navbarOption {
    margin: 5px;
    padding: 0 0 0px;
  }
  
  .navbarOption:hover {
    border-bottom: 2px solid var(--background-color);
    position: relative; 
    bottom: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  .navbarOption > h1 {
    padding: 5px;
    font-size: 27px;
    text-align: center;
    font-family: var(--font-form);
    color: var(--background-color);
  }
}
